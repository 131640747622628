.list-component{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .list-component-body{
    padding: 10px  0px 10px 10px;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    //display: flex;
    //flex-direction: column;
    .list-component-header{
      font-size: 15px;
      height: 34px;
      line-height: 34px;
      border: 1px solid #DADCDF;
      border-radius: 5px;
      background-color: #F1F3F4;
      margin-top: 4px;
      margin-left: 7px;
      padding-left: 24px;
      font-weight: 500;
      text-transform: capitalize;
      &.list-component-complex-header{
        padding-left: 0px !important;
      }
    }
    .list-component-item{
      height: 60px;
      border: 1px solid #DADCDF;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      margin-left: 7px;
      margin-top: 7px;
      align-items: center;
      cursor: pointer;
      padding-left: 24px;
      &.list-component-complex-item{
        padding-left: 0px !important;
      }
    }
  }
}

.list-item-component-simple{
  img{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 1px solid #DADCDF;
  }
  div:first-child{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #A182EC;
    border: 1px solid #DADCDF;
  }
  div:nth-child(2){
    margin-left: 5px;
  }
}