.list-questionnaire-header-icon{
  width: 50px;
}
.list-questionnaire-icon{
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  padding-right: 10px;
}
.list-questionnaire-name{
  width: 50%;
}
.list-questionnaire-type{
  width: 50%;
  text-transform: capitalize;
}
.list-questionnaire-options-btn{
  width: 52px;
}