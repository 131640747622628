.wrap-input-field{
  display: flex;
  flex-direction: row;
  position: relative;
  height: 36px;
  margin-bottom: 7px;
  align-items: center;
  input{
    flex-grow: 1;
    width: 309px;
    position: absolute;
  }
  .close-btn-preset{
    margin-left: 320px;
  }
}


.input-check-box-wrap{
  display: flex;
  flex-direction: row;
  align-items: center;
  input{
    margin-right: 12px;
  }
}