.modal-wrap{
  height: 100%;
  width: 356px;
  border-radius: 5px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  display: flex;
  flex-direction: column;
  position: relative;
  &.large-modal-wrap{
    width: 477px;
  }
  &.x-large-modal-wrap{
    width: 630px;
  }
  &.all-size-modal-wrap{
    width: 100%;
  }
  .modal-header{
    font-size: 16px;
    height: 56px;
    border-bottom:  1px solid #DADCDF;
    text-transform: capitalize;
    align-items: center;
  }
  .modal-body{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border-bottom: 1px solid #DADCDF;
    position: absolute;
    top: 56px;
    bottom: 62px;
    width: 100%;
    padding: 20px;
    overflow-y: auto;
  }
  .modal-footer{
    position: absolute;
    top: ~"calc(100% - 62px)";
    height: 62px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 14px 21px;
  }
}