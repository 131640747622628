.add-input-field-wrap{
  height: 36px;
  //width: 308px;
  border: 1px dashed #D4D4D4;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  background-color: #ffffff;
  cursor: pointer;
  &.disable-add{
    cursor: not-allowed;
  }
  div{
    margin-right: 10px;
  }
}