body {
  //width: 100%;
  //max-width: 380px;
  //margin: 0 auto;
}


ul {
  list-style: none;
  margin:0;
  padding:0;
  //border: 1px solid #DADCDF;
  border-radius: 5px;
  background-color: inherit;
}
li {
  border: 1px solid #DADCDF;
  border-radius: 5px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  position: relative;
  .li-item-wrap{
    display: flex;
    flex-direction: row;
  }
  .drag-fetcher{
    display: flex;
    flex-direction: row;
    height: 36px;
    align-items: center;
    // top-left/center-left/bottom-left
    position: absolute;
    margin-left: 10px;
    &.top-left{
      margin-top: 11px;
      align-self: flex-start;
    }
    &.center-left{
      align-self: center;
    }
    &.bottom-left{
      align-self: flex-end;
      bottom: 11px;
    }
    .drag-icon-wrap{
      cursor: grab;
      //position: absolute;
      //margin-left: 10px;
      //margin-top: 15px;
    }
    .num-of-item{
      height: 36px;
      width: 25px;
      border: 1px solid #DADCDF;
      background-color: #FFFFFF;
      margin-left: 9px;
      //margin-top: 10px;
      text-align: center;
      line-height: 36px;
    }
  }
}