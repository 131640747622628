@import "../style";
@import "../../../globalStyle/guideline";
.tolls-header{
  padding-left: 35px;
  .list-header-class;
  div:first-child{

  }
  button{
    padding: 7px 16px;
    font-weight: 500;
  }
  .header-route{
    display: flex;
    flex-direction: row;
    * {
      margin-right: 10px;
      &.is-routing{
        cursor: pointer;
      }
    }

  }
}