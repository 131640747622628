.one-question-wrap{
  width: 100%;
  .one-question-header{
    height: 55px;
    border-bottom: 1px solid #DADCDF;
    padding-left: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    justify-content: flex-end;
    padding-right: 24px;
    &.close-quest{
      margin-bottom: 0px;
    }
    .options-quest-icon-wrap{
      margin-left: 6px;
      cursor: pointer;
      .options-menu{
        height: 42px;
        width: 125px;
        background-color: #FFFFFF;
        box-shadow: 0 1px 5px 0 rgba(197,197,197,0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 1;
        &:hover{
          background-color: #C2C2C2;
        }
      }
    }
    .arrow-icon{
      cursor: pointer;
      flex-grow: 1;
      justify-content: flex-end;
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
  .one-question-body{
    padding-bottom: 27px;
    .input-row-2{
      display: flex;
      flex-direction: row;
      margin-top: 10px;
      padding: 0px 34px;
      .input-field{
        width: 50%;
        display: flex;
        flex-direction: row;
        margin-top: 14px;
        align-items: center;
        position: relative;
        .error-style{
          position: absolute;
          margin-left: 140px;
          margin-top: 27px;
        }
        &.second-item{
          margin-left: 95px;
        }
        .label{
          width: 140px;
        }
        input{
          flex-grow: 1;
        }
        select{
          flex-grow: 1;
        }
      }
    }
    .input-row-1{
      display: flex;
      flex-direction: row;
      margin-top: 16px;
      padding: 0px 34px;
      position: relative;
      &.answer-section{
        padding: 1px 35px;
      }
      .label{
        width: 140px;
      }
      .error-style{
        position: absolute;
        margin-left: 140px;
        bottom: -16px;
      }
      .answer-array-body{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        .answer-btn-section{
          display: flex;
          flex-direction: row;
          .add-answer-btn{
            width: 180px;
            margin-right: 8px;
          }
        }
        .error-style{
          margin-left: 0px;
        }
      }
      &.button-border{
        border-bottom: 1px solid #DADCDF;
        padding-bottom: 25px;
        .error-style{
          bottom: 0px;
        }
      }
    }
  }
}