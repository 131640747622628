.rules-body-wrapper{
   display: flex;
  flex-direction: column;
  .rule-body-details{
    padding-left: 15px;
    .error-style{
      margin-left: 120px;
      margin-top: -16px;
    }
  }
  .rule-filed{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    &.aline-top-field{
      align-items: flex-start;
    }
    .rule-label{
      width: 118px;
      &.subject-label{
        font-weight: 900;
      }
    }
    .rule-input{
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      .select-questionnaire-section{
        width: 200px;
        display: flex;
        flex-direction: column;
        .call-to-action-wrap{
          margin-bottom: 12px;
        }
      }
      .small-input{
        width: 150px;
        &.not-first{
          margin-left: 14px;
        }
      }
      .call-to-action-wrap{
        width: 200px;
      }
      select{
        width: 314px;
      }
      input{
        width: 314px;
      }
    }
  }
  .rule-separate-line{
    height: 1px;
    background-color: #DADCDF;
    margin-bottom: 20px;
  }
  .rule-btn-section{
    width: 100%;
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
    height: 60px;
    line-height: 32px;
    text-align: center;
    align-items: center;
    border-top: 1px solid #DADCDF;
    padding-right: 15px;
  }
}