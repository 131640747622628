.search-text-input-wrap{
  display: flex;
  flex-direction: column;
  width: 100%;
  input{
    width: 100%;
  }
  .suggest-options{
    border: 1px solid #DADCDF;
    background-color: #FFFFFF;
    box-shadow: 0 1px 5px 0 rgba(114,114,114,0.5);
    position: absolute;
    width: 100%;
    top: 36px;
    max-height: 100px;
    overflow: auto;
    z-index: 1;
    .not-existing-tag-option{
      display: flex;
      flex-direction: column;
      height: 85px;
      .explanation-text{
        font-size: 12px;
        font-style: oblique;
        font-weight: 500;
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
      .create-tag-text{
        flex-grow: 1;
        font-size: 13px;
        color: #4285F4;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        cursor: pointer;
        span{
          font-weight: 900;
          color: #4285F4;
        }
      }
    }
    .option-item{
      height: 40px;
      padding-top: 15px;
      padding-left: 12px;
    }
  }
  .selected-options{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .chips-item-wrap{
      width: max-content;
      min-width: 100px;
      max-width: 200px;
      margin-top: 9px;
      margin-right: 9px;
    }
  }

}