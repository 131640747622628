@import "../../../style";
.program-tab-page {
  .add-assessment-icon-wrap {
    position: relative;
    flex-grow: 1;
  }
  padding-left: 27px;
  padding-bottom: 31px;
  overflow-y: auto;
  font-size: 13px;
  height: ~"calc(100% - @{heightTenantListPageHeader})";
  width: ~"calc(100% - @{statusBodyWidth})";
  position: absolute;
  top: 60px;
  .setting-title {
    // height: 66px;
    line-height: 66px;
    display: flex;
    flex-direction: row;
    font-weight: 900;
    &.only-main-title {
      height: 98px;
      line-height: 98px;
      justify-content: flex-end;
      //margin: 31px 0px;
    }
    &.setting-title-top-border {
      border-top: 1px solid #dadcdf;
    }
    .sub-setting-title {
      flex-grow: 1;
      font-weight: normal;
      display: flex;
      flex-direction: row;
      align-items: center;
      input {
        &.emergency {
          width: 307px;
        }
      }
      .fix-size {
        width: 210px;
        font-size: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      .action-btn-section {
        width: 100%;
        justify-content: flex-end;
        display: flex;
        flex-direction: row;
        height: 32px;
        line-height: 32px;
        text-align: center;
      }
    }
    .main-setting-title {
      width: 191px;
    }
  }
  .settings-section {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 31px;
    &.without-title {
      .section-name {
        width: 191px;
        font-weight: 900;
        line-height: 66px;
      }
      .section-details {
        margin-top: 25px;
      }
    }
    .section-name {
      width: 191px;
    }
    .section-details {
      flex-grow: 1;
      padding-bottom: 31px;
      display: flex;
      flex-direction: column;
      &.not-last-item {
        border-bottom: 1px solid #dadcdf;
      }
      .assessment-body-wrapper {
        flex-grow: 1;
        .assessment-body {
          width: 200px;
          position: relative;
          .call-to-action-wrap {
            margin-bottom: 12px;
            div {
              text-transform: capitalize;
            }
          }
          .chips-item-wrap {
            width: 200px;
          }
        }
      }
      .assessment-body-box {
        min-height: 145px;
        width: 340px;
        border: 1px solid #dadcdf;
        padding: 14px 46px 26px 14px;
        position: relative;
        .error-style {
          margin-left: 49px;
        }
        .close-assessment-body-box-icon {
          position: absolute;
          top: 5.5px;
          right: 6.5px;
        }
        .assessment-name {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 13px 0px;
          .assessment-name-label {
            width: @assessmentLabelWidth;
          }
          .sub-title {
            font-size: 12px;
          }

          input {
            flex-grow: 1;
          }
        }
        .form-list-items-wrap {
          overflow-y: auto;
          overflow-x: hidden;
          padding-left: @assessmentLabelWidth;
          height: 220px;
          .tooltip-text {
            margin-left: 230px;
          }
          &.item-0 {
            height: 45px;
          }
          &.item-1 {
            height: 45px;
          }
          &.item-2 {
            height: 90px;
          }
          &.item-3 {
            height: 135px;
          }
          &.item-4 {
            height: 180px;
          }
          &.item-5 {
            height: 225px;
          }
          //height: 100px;
        }
      }
      .list-items-wrap {
        position: relative;
        width: 200px;
        display: flex;
        flex-direction: row;

      }
      .email-item {
        input {
          margin-right: 12px;
          width: 307px;
        }
      }
    }
  }
}
.removed {
  display: none !important;
}
.setting-content {
  width: 100%;
}
.section-details {
  width: 220px;
}