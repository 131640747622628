.empty-icon-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  .empty-icon-icon-content{
    margin-bottom: 21px;
  }
  .icon-text{
    font-size: 15px;
    font-weight: 500;
  }
  .secondary-button{
    margin-top: 21px;
    width: 148px;
  }
}