.user-message-pipe{
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 10px;
  bottom: 0px;
  z-index: 3;
}
.user-message-item{
  display: flex;
  flex-direction: row;
  height: 84px;
  width: 411px;
  border-radius: 5px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  margin-bottom: 25px;
  align-items: center;
  &.warning-message{
    border-left: 5px solid #FFD391;
  }
  &.success-message{
    border-left: 5px solid #4FCEC8;
  }
  .message-icon{
    padding-left: 24px;
    width: 72px;
  }
  .message-body{
     flex-grow: 1;
     display: flex;
     flex-direction: column;
     font-size: 13px;
    .message-title{
      font-weight: 900;
    }
  }
  .close-icon{
    width: 40px;
  }
}
