.add-form-search-inbox-wrap {
  margin-top: 10px;
  margin-bottom: 17px;
}
.add-form-list-item-wrap {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  .modal-list-item {
    display: flex;
    flex-direction: row;
    min-height: 25px;
    align-items: center;
    white-space: nowrap;
    label {
      cursor: pointer;
      margin-bottom: 0;
    }
    input[type="checkbox"] {
      cursor: pointer;
      margin-right: 8px;
    }
    div {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
