.wrap-create-consent-form{
  display: flex;
  flex-direction: column;
  .error-style{
    margin-left: 144px;
    margin-top: -16px;
  }
  .simple-input-field{
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    align-items: center;
    .input-label{
      width: 144px;
      &.text-label{
        align-self: flex-start;
        padding-top: 10px;
      }
    }
    .input-filed{
      flex-grow: 1;
      input{
        height: 36px;
        width: 314px;
      }
    }
  }
}
.react-mde{
  .grip{
    display: none;
  }
  .mde-preview{
    overflow-y: auto;
  }
}