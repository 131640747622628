@font-face {
  font-family: "Avenir";
  src: url("../../fontFiles/Avenir/AvenirLTStd-Black.otf");
  src: url("../../fontFiles/Avenir/AvenirLTStd-BlackOblique.otf");
  src: url("../../fontFiles/Avenir/AvenirLTStd-Book.otf");
  src: url("../../fontFiles/Avenir/AvenirLTStd-BookOblique.otf");
  src: url("../../fontFiles/Avenir/AvenirLTStd-Heavy.otf");
  src: url("../../fontFiles/Avenir/AvenirLTStd-HeavyOblique.otf");
  src: url("../../fontFiles/Avenir/AvenirLTStd-Light.otf");
  src: url("../../fontFiles/Avenir/AvenirLTStd-LightOblique.otf");
  src: url("../../fontFiles/Avenir/AvenirLTStd-Medium.otf");
  src: url("../../fontFiles/Avenir/AvenirLTStd-MediumOblique.otf");
  src: url("../../fontFiles/Avenir/AvenirLTStd-Oblique.otf");
  src: url("../../fontFiles/Avenir/AvenirLTStd-Roman.otf");
}

* {
  color: #54585d;
  font-family: Avenir;
  font-size: 13px;
}
.home-box {
  display: flex;
  flex-direction: column;
  /*background-color: aqua;*/
  height: 100%;
  position: absolute;
  width: 100%;
  padding-top: 20px;
  padding-left: 67px;
  padding-right: 67px;
  overflow: hidden;
}
.header-box {
  height: 44px;
  min-height: 44px;
  padding-left: 9px;
  border-bottom: 1px solid #dadcdf;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  font-size: 21px;
  font-weight: 200;
  line-height: 25px;
  .user-menu-icon {
    justify-content: flex-end;
    flex-grow: 1;
    display: flex;
    padding-right: 30px;
    svg {
      cursor: pointer;
    }
    /*.menu-icon{
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background-color: #8F6FDD;
      cursor: pointer;
    }*/
    .user-menu {
      position: absolute;
      right: 100px;
      top: 69px;
      border: 1px solid #dadcdf;
      box-shadow: 0 1px 5px 0 rgba(114, 114, 114, 0.5);
      .user-menu-item {
        height: 42px;
        width: 102px;
        padding: 12px;
        background-color: #ffffff;
        cursor: pointer;
        &:hover {
          background-color: #9e9aa0;
        }
      }
    }
  }
  .logo-valera {
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }
  .logo-valera-health {
  }
}
header-box .div {
  //font-weight: bold;
}
header-box .div:nth-child(1) {
  //font-weight: bold;
}
header-box .div:nth-child(2) {
}

.body-box {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  padding-right: 30px;
  .body-status {
    width: 159px;
    padding-left: 9px;
    overflow: hidden;
    border-right: 1px solid #dadcdf;
    display: flex;
    flex-direction: column;

    a {
      text-decoration: none;
      height: 50px;
      line-height: 50px;
      img {
        margin-right: 10px;
      }
      span {
        line-height: 10px;
      }
    }
  }
  .body-content {
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
}
