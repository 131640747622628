.consent-form-list-name{
  flex-grow: 1;
  padding-left: 25px;
  margin-left: 25px;
}
.consent-form-list-privacy{
  display: flex;
  text-align: left;
  width: 200px;
}
.react-mde{
  height: 413px;
  width: 530px;
  position: relative;
  display: flex;
  flex-direction: column;
  .mde-header{
    height: 46px;
  }
  .mde-text{
    flex-grow: 1;
  }
}