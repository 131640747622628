@backgroundColorOverflow: #C2C2C2;
/* Let's get this party started */
::-webkit-scrollbar {
  width: 10px;
  background-color: #F8F8F9;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: @backgroundColorOverflow;
}
::-webkit-scrollbar-thumb:window-inactive {
  background: @backgroundColorOverflow;
}

/* Let's get this party started */
::-webkit-scrollbar {
  width: 10px;

}
