.create-media-modal-body{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .error-style{
    margin-left: 120px;
    height: 20px;
    margin-top: -20px;
  }
  .modal-section{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    .category-section{
      width: 120px;
      align-self: flex-start;
      line-height: 36px;
    }
    .details-section{
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      position: relative;
      &.paragraph-section{
        flex-direction: column;
      }
      .radio-group{
        display: flex;
        flex-direction: column;
        width: 100%;
        .error-style{
          margin-left: 25px;
          height: 20px;
          margin-top: -15px;
        }
        .radio-element{
          display: flex;
          flex-direction: row;
          margin-bottom: 17px;
          .radio-element-body{
            display: flex;
            flex-direction: row;
            width: 100%;
            .not-show-input{
              display: none;
            }
            .link-button{
              margin: 10px 0px 0px 0px;
              padding: 0px;
              text-align: left;
              width: fit-content;
            }
          }
        }
      }
    }
  }

}