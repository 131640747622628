.questions-item-wrap{
  margin-top: 4px;
  .questions-body{
    //height: 362px;
    width: 100%;
    border: 1px solid #DADCDF;
    border-radius: 5px;
    background-color: #F8F9F9;
    margin-top: 4px;
    .questions-body-details{
      padding: 14px;
      border-bottom: 1px solid #DADCDF;
      display: flex;
      flex-direction: column;
      .add-quest-wrap{
        //width: 100%;
      }
    }
  }
}