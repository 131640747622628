.dashboard-datepicker {
  // width: 230px;
  width: 200px;

  .react-date-picker__wrapper {
    border: 1px solid #dadcdf;
    border-radius: 5px;
  }

  .react-date-picker__wrapper input {
    width: initial;
    border: none;
  }

  .react-date-picker__inputGroup {
    display: flex;
    align-items: center;
  }

  .react-date-picker__wrapper {
    height: 36px;
  }
  .react-date-picker {
    width: 100%;
  }
  .react-calendar__tile--active,
  .react-calendar__tile--now.react-calendar__tile--active {
    background: #317b8c !important;
  }

  .react-calendar__tile--active abbr {
    color: #ffffff !important;
  }

  .react-calendar__tile:disabled {
    background-color: #ffffff !important;
  }

  .react-calendar__tile:disabled abbr {
    color: #dadcdf;
  }
  .react-date-picker__calendar {
    width: 230px;
  }
  .react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
    font-size: 13px;
  }

  .react-calendar__month-view__days__day {
    border-radius: 25px;
  }

  .react-calendar__tile--now,
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background-color: #f5f5f5 !important;
  }

  .react-date-picker__calendar.react-date-picker__calendar--open {
    box-shadow: 0 1px 5px 0 rgba(197, 197, 197, 0.5);
  }

  .react-calendar {
    border: none !important;
  }
  .react-date-picker__inputGroup__input:invalid {
    background-color: inherit;
    color: #d24667;
    caret-color: #54585d;
  }
}
