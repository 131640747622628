.button-new{
  padding: 0px 12px;
  height: 32px;
  min-width: 65px;
  border-radius: 3px;
  font-size: 13px;
  line-height: 32px;
  cursor: pointer;
  text-align: center;
}

.primary-button{
  .button-new;
  color: #ffffff;
  background-color: #A182EC;
  &:hover{
    background-color: #8F6FDD
  }
  &.disable{
    background-color: #B9C2CB;
    cursor: default;
  }
}

.secondary-button{
    .button-new;
    color: #A182EC;
    background-color: #ffffff;
    border: 1px solid #A182EC;
    &:hover{
      background-color: #F9F9FA;
    }
    &.disable{
      border: 1px solid #B9C2CB;
      background-color: #FFFFFF;
      color: #B9C2CB;
      cursor: default;
    }
  }

.link-button{
  .button-new;
  color: #4285F4;
  background-color: inherit;
  &:hover{
    background-color: #F9F9FA;
  }
  &.disable{
    color: #B9C2CB;
    cursor: default;
    background-color: inherit;
  }
}


.button_simulate_loader{
  width: 110px;
  height: 40px;
  position: relative;
  margin: auto;
  .loader {
    position: relative;
    margin: auto;
    border: 16px solid #3498db;
    border-radius: 50%;
    border-top: 16px solid #f3f3f3;
    width: 10px;
    height: 10px;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
  }
}