@statusBodyWidth: 223px;
@assessmentLabelWidth: 49px;
@heightTenantListPageHeader: 60px;
.list-header-class{
  display: flex;
  flex-direction: row;
  height: 60px;
  border-bottom: 1px solid #DADCDF;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.tenants-list-page{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  //.tenants-list-page-header{
  //  height: @heightTenantListPageHeader;
  //  padding-left: 17px;
  //  display: flex;
  //  flex-direction: row;
  //  align-items: center;
  //}
  .tenants-list-page-body{
    position: absolute;
    width: 100%;
    //top: @heightTenantListPageHeader;
    height: 100%;
    //height: ~"calc(100% - @{heightTenantListPageHeader})";
  }
}

