.chips-item-wrap{
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 8px;
  height: 36px;
  width: 100%;
  border: 1px solid #DADCDF;
  border-radius: 4px;
  background-color: #F8F8F9;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 10px;
  .tooltip-text{
    margin-left: 205px;
  }
  &:hover{
    .tooltip-text{
      display: block;
    }

  }
  &.disable-chips-item{
    div{
      color: rgba(84,88,93,0.5);
    }
  }
  div{
    height: 36px;
    line-height: 36px;
  }
}