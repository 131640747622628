.questionnaire-item-title{
  height: 34px;
  margin-top: 4px;
  border: 1px solid #DADCDF;
  border-radius: 5px;
  background-color: #F1F3F4;
  &.is-close{
    background-color: #ffffff;
  }
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  .main-number{
    background-color: #97A2A7;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    color: #ffffff;
    font-size: 11px;
    margin-left: 14px;
    margin-right: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.close{
      color: #54585D;
      background-color: #ffffff;
    }
  }
  .main-icon{
    margin-left: 14px;
    margin-right: 11px;
  }
}