.element-element-body-modal{
  display: flex;
  flex-direction: row !important;
  .special-input-section{
    height: 50%;
    display: flex;
    flex-direction: row;
    //img{
    //  width: 70px;
    //  height: 70px;
    //}
    //.not-show-input{
    //  display: none;
    //}
  }
  .simple-input-section{
    height: 25%;
    display: flex;
    flex-direction: row;
    align-items: center;
    input{
      width: 100%;
    }
  }
  .element-category-name{
    display: flex;
    flex-direction: column;
    width: 120px;
  }
  .element-category-details{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    .error-style{
      margin-left: 0px;
      height: 20px;
      margin-top: -5px;
    }
    .radio-group{
      display: flex;
      flex-direction: column;
      width: 100%;
      .error-style{
        margin-left: 25px;
        height: 20px;
        margin-top: -15px;
      }
      .radio-element{
        display: flex;
        flex-direction: row;
        margin-bottom: 17px;
        .radio-element-body{
          display: flex;
          flex-direction: row;
          width: 100%;
          input{
            width: 100%;
          }
          .not-show-input{
            display: none;
          }
          .link-button{
            margin: 10px 0px 0px 0px;
            padding: 0px;
            //text-align: left;
            width: fit-content;
          }
          .image-button-section{
            padding-left: 15px;
          }
        }
      }
    }
  }
}