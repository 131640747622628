.login-screen-page{
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: url("../../image/login_background.jpg") no-repeat center center fixed;
  display: flex;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  .login-user-wrapper{
    position: relative;
    padding: 18px;
    height: 455px;
    width: 400px;
    max-height: 100%;
    border-radius: 5px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    margin: auto;
    overflow-x: hidden;
    overflow-y: auto;
    .valera-icons-section{
      height: 192px;
      width: 100%;
      border-bottom: 1px solid #DADCDF;
      margin-bottom: 13px;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    .valera-user-input{
      height: 203px;
      width: 100%;
      display: flex;
      flex-direction: column;
      .error-text{
        color: red;
        text-align: center;
        margin-top: 10px;
      }
      .input-field{
        margin-top: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        .input-name-label{
          width: 110px;
        }
        input{
          flex-grow: 1;
        }
      }
      .text-instruction{
        display: flex;
        flex-direction: row;
        align-items: center;
        &.main-text{
          margin-bottom: 13px;
        }
        .start{
          justify-self: flex-start;
          width: 60px;
          .link-button{
            margin-top: 0px !important;
            text-align: left;
            padding: 0pc;
          }
        }
        .end{
          justify-self: flex-end;
          width: 60px;
        }
        .center{
          flex-grow: 1;
          justify-self: center;
          text-align: center;
          &.bold-text{
            font-weight: 900;
          }
        }
      }
      .primary-button{
        margin-top: 16px;
      }
      .link-button{
        margin-top: 16px;
      }
    }
  }
}