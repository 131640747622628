.icon-button-component{
  color: #4285F4;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 18px;
  cursor: pointer;
  &.not-btn{
    cursor: inherit;
  }
  &.disable-btn{
    cursor: default;
    svg path{
        fill: #B9C2CB;
    }
    svg polygon{
      fill: red;
    }
  }
  span{
    color: #4285F4;
    margin: 0 0 0 10px;
  }
}