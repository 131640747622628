.answer-item-wrapper{
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: 60px;
  border-radius: 5px;
  background-color: #F8F9F9;
  padding-left: 60px;
  align-items: center;
  &.free-text{
    height: 82px;
    ::before{
      content: "Text will be displayed:";
      position: absolute;
      top: 0px;
      left: 22px
    }
  }

  input[type=text]{
    width: 368px;
  }
  input[type=number]{
    height: 36px;
    width: 40px;
    border: 1px solid #DADCDF;
    background-color: #FFFFFF;
    margin-left: 17px;
    padding: 0px 0px 0px 5px;
  }
  .close-item-icon{
    margin-left: 7px;
    margin-right: 40px;
    cursor: pointer;
  }
}