header.users-header {
  height: 60px;
  border-bottom: 1px solid #dadcdf;
}
.treatment-header .primary-button {
  width: 80px;
  margin-left: 10px;
}

.treatment-header {
  display: flex;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
  padding-top: 15px;
}
.treatment-page {
  padding-left: 10px;
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.footer .right-hide {
  display: flex;
  align-items: center;
}

.footer .right-hide input {
  width: 35px;
  text-align: center;
  margin: 0 10px;
  border-radius: 0;
  padding: 0;
}
.footer .page-arrows {
  display: flex;
  margin: 0 5px 0 20px;
}
.treatment-page .table-container {
    height: calc(100vh - 240px);
}

//EDIT PATIENT POPUP
.edit-patient-popup {
  background: white;
  min-width: 600px;
  max-width: calc(100vw - 300px);
}

.edit-patient-popup span.edit-patient-label {
  white-space: nowrap;
  display: block;
  width: 200px;
}
.edit-patient-popup span.edit-patient-label {
  margin-left: 30px;
}
.edit-patient-popup input,
.edit-patient-popup select {
  width: 100% !important;
}
.edit-patient-popup .popup-body {
  max-height: calc(100vh - 200px);
  overflow: auto;
}
.edit-patient-popup .form-line {
  grid-template-columns: 150px auto;
}
.edit-patient-popup .popup-footer {
  position: relative;
}
.edit-patient-popup .form-comp {
  padding: 0 !important;
}
.edit-patient-popup .dashboard-datepicker {
  width: 100%;
}
.selected-element {
  display: flex;
  padding: 8px;
  justify-content: space-between;
  border: 1px solid #dadcdf;
  border-radius: 4px;
  background-color: #f8f8f9;
  float: left;
  margin-right: 10px;
  margin-top: 10px;
  height: 36px;
}
span.selected-element-title {
  white-space: nowrap;
  margin-right: 5px;
  max-width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.edited-patient-popup {
  max-height: calc(100vh - 300px);
  overflow: auto;
}
.pending-cell {
  border-top: 1px solid #dadcdf;
  border-bottom: 1px solid #dadcdf;
  height: 60px;
}
.pending-picture {
  width: 50px;
}
.pending-name {
  width: 250px;
}
td.pending-cell.pending-picture {
  border-left: 1px solid #dadada;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}