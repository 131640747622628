.main-item-wrap {
  height: 465px;
  &.is-close {
    height: auto;
  }
  &.with-click {
    cursor: pointer;
  }
  .main-item-body {
    height: 427px;
    width: 100%;
    border: 1px solid #dadcdf;
    border-radius: 5px;
    background-color: #f8f9f9;
    margin-top: 4px;
    .main-item-body-details {
      height: 372px;
      border-bottom: 1px solid #dadcdf;
      padding-left: 16px;
      padding-right: 22px;
      display: flex;
      flex-direction: row;
      padding-top: 12px;
      overflow: auto;
      .details-column {
        width: 50%;
        display: flex;
        flex-direction: column;
        &.end-column {
          align-items: flex-end;
        }
        .simple-input {
          height: 45px;
          margin-bottom: 19px;
          display: flex;
          flex-direction: row;
          align-items: center;
          .category {
            width: 118px;
          }
          .inputs {
            width: 314px;
            display: flex;
            flex-direction: column;
            position: relative;
            .error-style {
              position: absolute;
              margin-top: 36px;
            }
            input {
              width: 100%;
            }
            .score-inputs {
              display: flex;
              flex-direction: row;
              input[type="number"] {
                height: 36px;
                width: 123px;
                border: 1px solid #dadcdf;
                background-color: #ffffff;
                margin-right: 8px;
              }
              input[type="number"]::placeholder {
                font-size: 12px;
              }
            }
            select {
              width: 100%;
            }
          }
        }
        .text-area-input {
          height: 116px;
          margin-bottom: 19px;
          display: flex;
          flex-direction: row;
          .category {
            width: 118px;
            margin-top: 3px;
          }
          textarea {
            border: 1px solid #dadcdf;
            width: 314px;
            height: 116px;
            resize: none;
            padding: 10px;
          }
        }
      }
    }
    .main-item-body-btn-section {
      padding-left: 16px;
      padding-right: 22px;
      height: 54px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      .secondary-button {
        width: 70px;
      }
    }
  }
}
