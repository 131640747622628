.wrap-condition{
  display: flex;
  flex-direction: column;
  .condition-row{
    height: 56px;
    border: 1px solid #DADCDF;
    border-radius: 5px;	background-color: #F8F9F9;
    margin-bottom: 11px;
    display: flex;
    flex-direction: row;
    padding-left: 13px;
    padding-right: 9px;
    justify-content: center;
    width: max-content;
    .condition-item{
      display: flex;
      flex-direction: row;
      width: 85px;
      align-items: center;
      &.two-item{
        width: 140px;
      }
      select{
        width: 60px;
        padding: 0px 5px;
        &.second-select{
          margin-left: 4px;
        }
      }
      .close-icon-item{
        margin-left: 6px;
        cursor: pointer;
      }
      .separate-line{
        margin: 0px 6px;
        height: 7.5px;
        width: 1px;
        border-right: 2px solid #DADCDF;
      }
    }
    .add-or-icon{
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  .add-row-btn{
    height: 36px;
    width: 64px;
    &.empty-conditions{
      width: 145px;
    }
  }
}