@import "../style";
.tenant-page-program-page {
  top: @heightTenantListPageHeader;
  height: ~"calc(100% - @{heightTenantListPageHeader})";
  position: absolute;
  width: ~"calc(100% - @{statusBodyWidth})";
  .program-list-header {
    height: @heightTenantListPageHeader;
    padding-left: 17px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .list-component {
    height: ~"calc(100% - @{heightTenantListPageHeader})";
  }
}

.tenant-page-patients {
  height: 100%;
}
.tenant-page-patients,
.tenant-page-providers {
  // padding: 10px;
  // height: 80vh;
  // overflow: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
}
.tenant-page-providers {
  padding-left: 10px;
  height: calc(100vh - 240px);
}
.tenant-page-providers table.basic-table tbody tr {
  cursor: pointer;
}
.no-user-button {
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-user-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}
.no-user-container .primary-button {
  width: 140px;
}
