@import "../../../globalStyle/guideline";
.questionnaire-component{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  .questionnaire-component-header{
    height: 60px;
    padding-left: 17px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .questionnaire-component-body{
    position: absolute;
    width: 100%;
    top: @heightPageHeader;
    height: ~"calc(100% - @{heightPageHeader})";
  }
}
.questionnaire-list-item{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
  padding-left: 25px;
  &.questionnaire-header{
    *{
      font-size: 15px;
      font-weight: 500;
    }
  }
}