.create-tenant-body-modal{
  display: flex;
  flex-direction: row !important;
  .special-input-section{
    height: 50%;
    display: flex;
    flex-direction: row;
    img{
      width: 70px;
      height: 70px;
    }
    .not-show-input{
      display: none;
    }
    div{
      margin-left: 15px;
    }
  }
  .simple-input-section{
    height: 25%;
    display: flex;
    flex-direction: row;
    align-items: center;
    input{
      width: 100%;
    }
  }
  .add-tenant-category-name{
    display: flex;
    flex-direction: column;
    width: 120px;
  }
  .add-tenant-category-details{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}