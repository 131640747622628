.menu-item-component{
  display: flex;
  height: 100%;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  color: #54585D;
  &:hover{
    color: #000000;
  }
  &.disable{
    cursor: default;
    color: #B9C2CB;
  }
  span{
    margin-left: 9px;
  }
}