.appointment-reminder {
  margin-bottom: 30px;
}

.appointment-notification {
  background: #fcfcfc;
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 10px;
  margin: 10px 30px 10px 0;
  position: relative;

  & > .icon-button-component {
    position: absolute;
    right: 30px;
    top: 20px;
  }
}

.appointment-reminder {
  .add-input-field-wrap {
    width: 200px;
    background: inherit;
  }

  .reminder {
    display: flex;
    align-items: center;
    margin-top: 10px;
    width: 100%;
  }
  .icon-button-component {
    margin-left: 10px;
  }
}
.reminder-message {
  width: 670px;
  font-style: italic;
  line-height: 30px;
}

.reminder-message p {
  margin-bottom: 0;
}