select,
input[type="text"],
input[type="password"],
input[type="date"],
input[type="number"],
input[type="search"] {
  height: 36px;
  border: 1px solid #dadcdf;
  padding: 10px;
  border-radius: 5px;
}
input[type="text"].MuiInputBase-input {
  border: none;
}
input[type="radio"] {
  display: none;
}
input[type="radio"] + label {
  width: 15px;
  height: 15px;
  margin: 10px 10px 10px 0px;
  cursor: pointer;
  background: url("../image/icon_radiobutton_unchecked.png") no-repeat center
    center;
}
input[type="radio"]:checked + label {
  margin: 10px 10px 10px 0px;
  width: 15px;
  height: 15px;
  background: url("../image/icon_radiobutton_checked.png") no-repeat center
    center;
}
select {
  height: 36px;
  border: 1px solid #dadcdf;
  padding: 0px 10px;
  background-color: #ffffff;
  &:disabled {
    color: #c2c2c2;
  }
}
.tooltip-text {
  position: absolute;
  display: none;
  width: max-content;
  background-color: #f0eeef;
  box-shadow: 0 0 5px 0 rgba(159, 159, 159, 0.5), 0 0 0.7px 0 rgba(0, 0, 0, 0.5);
  color: #54585d;
  font-size: 11px;
  height: 18px !important;
  line-height: 9px !important;
  padding: 5px;
  align-self: center;
}

@heightPageHeader: 60px;
.list-header-class {
  display: flex;
  flex-direction: row;
  height: @heightPageHeader;
  min-height: @heightPageHeader;
  border-bottom: 1px solid #dadcdf;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
[class^="arrow-"] {
  width: 7px;
  height: 7px;
  margin-left: 5px;
  border-top: 1px solid #54585d;
  border-left: 1px solid #54585d;
  margin-bottom: 4px;
  display: inline-block;
  cursor: pointer;
}
[class^="arrow-"].disabled {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}
.arrow-down {
  transform: rotate(225deg);
}
.arrow-top {
  transform: rotate(45deg);
}
.arrow-right {
  transform: rotate(135deg);
}
.arrow-left {
  transform: rotate(315deg);
}
.hide-arrow input::-webkit-outer-spin-button,
.hide-arrow input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.invalid-field,
select.invalid-field,
textarea.invalid-field {
  border-color: #d24667 !important;
}