@import "./style";

.tenant-component{
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  position: relative;
  .tenant-component-status{
    width: @statusBodyWidth;
    padding-left: 23px;
    padding-right: 23px;
    padding-top: 15px;
    border-right: 1px solid #DADCDF;
    height: 100%;
    display: flex;
    flex-direction: column;
    .paragraph{
      height: 70px;
      margin:  10px 0px 0px 0px;
      display: flex;
      flex-direction: row;
      font-size: 13px;
      div{
        width: 50%;
      }
      img{
        height: 70px;
        width: 70px;
      }
      button{
        height: 33px;
        width: 173px;
      }
      .paragraph-title{
        font-weight: 900;
      }
      &.description-box{
        text-overflow: ellipsis;
        height: 70px;
        overflow: hidden;
      }
    }
  }
  .tenant-component-body{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    .tenant-component-headers{
      .list-header-class;
    }
    .tenant-component-inside-body{
      height: ~"calc(100% - @{heightTenantListPageHeader})";
      top: @heightTenantListPageHeader
      //height: ;
    }
  }
}