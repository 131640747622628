.item-rule-wrapper{
  padding: 27px 0px 0px 27px;
  .item-rule-content{
    width: 100%;
    margin-bottom: 8px;
    border: 1px solid #DADCDF;
    border-radius: 5px;
    .rule-item-header{
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 57px;
      width: 100%;
      padding-left: 22px;
      .options-rule-icon-wrap{
        margin-left: 6px;
        cursor: pointer;
        .options-menu{
          height: 42px;
          width: 125px;
          background-color: #FFFFFF;
          box-shadow: 0 1px 5px 0 rgba(197,197,197,0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          z-index: 1;
          &:hover{
            background-color: #C2C2C2;
          }
        }
      }
      .header-end-section{
        flex-grow: 1;
        justify-content: flex-end;
        display: flex;
        align-items: center;
        height: 100%;
        flex-direction: row;
        padding-right: 19px;
        .header-click-area{
          flex-grow: 1;
          height: 100%;
          cursor: pointer;
        }
        .line-separate{
          height: 7.5px;
          width: 1px;
          background-color: #DADCDF;
          margin: 0px 15px;
        }
        .is-open-icon-wrapper{
          cursor: pointer;
        }
      }
    }
    .rule-item-details{
      border-top: 1px solid #DADCDF;
      padding: 15px 0px 0px 0px;
    }
  }
  .add-rule-wrap{
    margin-bottom: 10px;
  }
}
.escalationWindowError {
  margin-left: 285px !important;
}