.component-headers-nav-link{
  //line-height: 49px;
  text-align: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  position: relative;
  a{
    width: 104px;
    height: 100%;
    line-height: 60px;
    &:hover{
      text-decoration: none;
      span{
        color: #000000;
      }
    }
  }

}