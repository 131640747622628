.list-media-header-icon{
  width: 50px;
}
.list-media-icon{
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  padding-right: 10px;
}
.list-media-name{
  width: 50%;
}
.list-media-category{
  width: 20%;
  text-transform: capitalize;
}
.list-media-tags{
  width: 30%;
}
.list-media-options-btn{
  width: 52px;
}